<div class="user-inner-menu-wrapper" [class.sticky-to-top]="stickyToTop" [class.in-mobile-sidenav]="inMobileSidenav">
  <!--
    <div class="user-menu-header">
    <div class="mb-16-px">



      <app-user-image-block  [firstName]="user.firstName || user.name" [lastName]="user.lastName || ''" [role]="user?.role" [photoUrl]="user?.photoUrl"
                [showActions]="false" [showUsername]="true" [showRole]="user?.role ? true : false" >
              </app-user-image-block>
    </div>
    <div class="profile-summary-block">
      <div class="profile-info-block">
        <p class="profile-info-label">Phone</p>
        <p class="profile-info-value">
          <a href="tel:{{user.phone}}">{{user.phone || '-'}}</a>
        </p>
      </div>
      <div class="profile-info-block">
        <p class="profile-info-label">Email</p>
        <p class="profile-info-value">
          <a href="mailTo:{{user.email}}">{{user.email  || '-'}}</a>
        </p>
      </div>

    </div>
  </div>  
  -->

  <div class="user-menu-body">
    <div class="user-inner-navigation-menu">
      @for (item of profileMenuList; track item) {
      @if(item.visible){
      <a (click)="!item.disabled && triggerEventsAfterLinkClick()" pRpple class="menu-link"
        [class.disabled]="item.disabled" [class.active]="item.active" [routerLink]="item.routerLink"
        [queryParams]="item.queryParams">
        <div class="left">
          @if(item.icon){
          <span class="prime-icon {{item.icon}}"></span>
          } @else if(item.iconUrl){
          <img [src]="item.active ? item.activeIconUrl : item.iconUrl" alt="{{item.label}}" />
          }
          <span class="text">{{item.label}}</span>
        </div>
        <div class="right">
          @if(item.pulsingBubble){
          <div class="bubble {{item.bubbleClass}}"></div>
          }
          @if (item.count) {<span class="count">{{item.count}}</span>}
        </div>

      </a>
      }

      }
    </div>
  </div>
</div>
